import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoMobile from "../assets/logoMobile.svg";
import downArrow from "../assets/downArrow.svg";
import rightArrow from "../assets/rightArrow.svg";
import "react-image-gallery/styles/css/image-gallery.css";
import axios from "axios";
import search from "../assets/search.svg";
import { ApiEndPoints, Constants } from "../Environment";
import SortFilter from "../assets/Sort_filter.svg";
import NoProductAvl from "../assets/NoProductAvl.svg";
import NoImageAvl from "../assets/NoImageAvl.svg";
import Location from "../assets/locationBtn.svg";
import Login from "../assets/loginBtn.svg";
import BackArrow from "../assets/BackArrow.svg";
import { Tooltip } from "antd";

const Product = () => {
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const initialProducts = location.state?.products || [];
  const [currentLocation, setCurrentLocation] = useState(null);
  const [products, setProducts] = useState(initialProducts);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, [products]); // The effect will trigger whenever 'products' changes

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    fetchProducts(searchText);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchClick = () => {
    handleSearch();
  };

  const handleBackClick = () => {
    navigate(-1); // This navigates back to the previous page
  };

  const fetchProducts = async (searchText) => {
    const payload = {
      latitude: currentLocation?.latitude || 0,
      longitude: currentLocation?.longitude || 0,
      brand: searchText,
      is_new: "",
    };

    try {
      const response = await axios.post(
        Constants.BaseURL_Product + ApiEndPoints.Seacrch_products,
        payload
      );
      setProducts(response?.data?.data); // Adjust according to the actual response structure
      console.log(response?.data?.data, "setProducts");

      // Navigate to "/products" route and pass the products data
      navigate("/products", { state: { products: response?.data?.data } });
    } catch (error) {
      console.error(
        "Error fetching products: ",
        error.response || error.message
      );
    }
  };

  // Function to parse the pic_filename field and extract image URLs
  const getProductImages = (productImages) => {
    if (!productImages || productImages.length === 0) {
      return [];
    }
    return productImages.map((image) => ({
      original:
        image.img_url || `https://yourimagebaseurl.com/${image.image_path}`,
      thumbnail:
        image.img_url || `https://yourimagebaseurl.com/${image.image_path}`,
    }));
  };

  useEffect(() => {
    // Log the products data for debugging
    console.log("Products data:", products);
    products.forEach((product) => {
      if (product.product_images && product.product_images.length > 0) {
        const imageUrls = getProductImages(product.product_images);
        console.log(`Image URLs for product ${product.name}:`, imageUrls);
      }
    });
  }, [products]);

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  return (
    <>
      <header className="header-fixed xl:px-20 md:px-16 bg-primary xl:flex xl:justify-between md:flex md:justify-between items-center heightMobile">
        <div className="flex xl:gap-16 md:gap-16 items-center logoLocation">
          <img
            className="cursor-pointer logoDesktop"
            src={logo}
            alt="Logo"
            onClick={() => navigate("/")}
          />
          <img
            className="cursor-pointer logoMobile"
            src={logoMobile}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <div className="flex items-baseline gap-2 cursor-pointer loginDesktop">
            <div className="text-white text-base font-medium">Location</div>
            <img src={downArrow} alt="Down Arrow" />
          </div>
          <div className="flex gap-6">
            <img className="locMobile" src={Location} alt="Location" />
            <img className="locMobile" src={Login} alt="Login" />
          </div>
        </div>
        <div className="search-container-product">
          <input
            type="text"
            className="search-input"
            placeholder="Search your product here"
            value={searchText}
            onChange={handleSearchTextChange}
            onKeyDown={handleKeyDown}
          />
          <img
            className="cursor-pointer"
            onClick={handleSearchClick}
            src={search}
            alt="Search"
          />
        </div>
        <div className="loginPadding loginDesktop">
          <button className="flex justify-center items-center w-[112px] h-[48px] bg-white gap-2 rounded-[100px] loginMobile">
            <a className="text-base font-semibold uppercase">Login</a>
            <img src={rightArrow} alt="Right Arrow" />
          </button>
        </div>
      </header>

      <main className="content mobile-product">
        {/* Display Products Passed from Home Component */}
        <section className="xl:px-20 md:px-16">
          <button
            onClick={handleBackClick}
            className="xl:px-0 md:px-0 flex items-center gap-2 pt-4"
          >
            <img src={BackArrow} alt="Back" />
            <p className="font-medium">Back</p>
          </button>
          <div>
            <div className="flex justify-between">
              <h3 className="text-[20px] font-medium text-primary py-4">
                Products
              </h3>
              <img className="xl:pr-10" src={SortFilter} alt="" />
            </div>
            {products.length > 0 ? (
              <ul className="product-list">
                {products.map((product) => (
                  <Link
                    to={`/product/${product.item_id}`}
                    state={{
                      product,
                      products,
                      is_avalible: true,
                      is_Distance: true,
                    }}
                  >
                    <li key={product.item_id} className="product-item">
                      {product.product_images &&
                      product.product_images.length > 0 ? (
                        <img
                          src={
                            getProductImages(product.product_images)[0].original
                          }
                          alt={product.name}
                          className="product-thumbnail"
                        />
                      ) : (
                        <img src={NoImageAvl} alt="No image available" />
                      )}
                      <Tooltip title={capitalizeWords(product.name)}>
                        <h4 className="text-secondary w-[140px] text-wrap font-bold text-[16px] py-2 flex-wrap">
                          {truncateString(capitalizeWords(product.name), 10)}
                        </h4>
                        <p className="text-GreenRS font-medium text-[16px]">
                          ₹ {product.unit_price}
                        </p>
                      </Tooltip>
                    </li>
                  </Link>
                ))}
              </ul>
            ) : (
              <img src={NoProductAvl} alt="No product available" />
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default Product;
