import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiEndPoints, Constants } from "../Environment";
import logo from "../assets/logo.svg";
import logoMobile from "../assets/logoMobile.svg";
import downArrow from "../assets/downArrow.svg";
import rightArrow from "../assets/rightArrow.svg";
import BackArrow from "../assets/BackArrow.svg";
import locationIcon from "../assets/location.svg";
import "react-image-gallery/styles/css/image-gallery.css";
import search from "../assets/search.svg";
import NoShopAvl from "../assets/NoShopAvl.svg";
import Location from "../assets/locationBtn.svg";
import Login from "../assets/loginBtn.svg";

const Shops = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { searchshops } = location.state || {};
  const [currentLocation, setCurrentLocation] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  const handleBackClick = () => {
    navigate(-1); // This navigates back to the previous page
  };

  const handleShopClick = (shop) => {
    navigate(`/SearchShopProfile/${shop.company_id}/${shop.person_id}`, {
      state: { shopProfile: shop },
    });
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    fetchProducts(searchText);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchClick = () => {
    handleSearch();
  };

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => capitalizeFirstLetter(word))
      .join(" ");
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const fetchProducts = async (searchText) => {
    const payload = {
      latitude: currentLocation?.latitude || 0,
      longitude: currentLocation?.longitude || 0,
      brand: searchText,
      is_new: "",
    };

    try {
      const response = await axios.post(
        Constants.BaseURL_Product + ApiEndPoints.Seacrch_products,
        payload
      );
      setProducts(response?.data?.data); // Adjust according to the actual response structure
      console.log(response?.data?.data, "setProducts");

      // Navigate to "/products" route and pass the products data
      navigate("/products", { state: { products: response?.data?.data } });
    } catch (error) {
      console.error(
        "Error fetching products: ",
        error.response || error.message
      );
    }
  };

  return (
    <>
      <header className="header-fixed xl:px-20 md:px-16 bg-primary xl:flex xl:justify-between md:flex md:justify-between items-center heightMobile">
        <div className="flex xl:gap-16 md:gap-16 items-center logoLocation">
          <img
            className="cursor-pointer logoDesktop"
            src={logo}
            alt="Logo"
            onClick={() => navigate("/")}
          />
          <img
            className="cursor-pointer logoMobile"
            src={logoMobile}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <div className="flex items-baseline gap-2 cursor-pointer loginDesktop">
            <div className="text-white text-base font-medium">Location</div>
            <img src={downArrow} alt="Down Arrow" />
          </div>
          <div className="flex gap-6">
            <img className="locMobile" src={Location} alt="Location" />
            <img className="locMobile" src={Login} alt="Login" />
          </div>
        </div>
        <div className="search-container-product">
          <input
            type="text"
            className="search-input"
            placeholder="Search your product here"
            value={searchText}
            onChange={handleSearchTextChange}
            onKeyDown={handleKeyDown}
          />
          <img
            className="cursor-pointer"
            onClick={handleSearchClick}
            src={search}
            alt="Search"
          />
        </div>
        <div className="loginPadding loginDesktop">
          <button className="flex justify-center items-center w-[112px] h-[48px] bg-white gap-2 rounded-[100px] loginMobile">
            <a className="text-base font-semibold uppercase">Login</a>
            <img src={rightArrow} alt="Right Arrow" />
          </button>
        </div>
      </header>

      <main className="content mobile-product">
        <button
          onClick={handleBackClick}
          className="xl:px-20 md:px-16 flex items-center gap-2 pt-4"
        >
          <img src={BackArrow} alt="Back" />
          <p className="font-medium">Back</p>
        </button>
        <section className="xl:px-20 md:px-16 pt-6">
          <div className="shops-container">
            <h3>Shops</h3>
            {searchshops && searchshops.length > 0 ? (
              <ul className="flex flex-wrap gap-8 xl:my-6 md:my-6 my-4">
                {searchshops.map((shop) => (
                  <li
                    key={shop?.person_id}
                    className="xl:flex-basis-30 border-[1px] border-[#0000001a] rounded-[8px] p-4 xl:w-[calc(32.33%-1rem)] md:w-[calc(32.33%-1rem)] w-full cursor-pointer " // Adjust the width for three items per row
                    onClick={() => handleShopClick(shop)}
                  >
                    <div>
                      {shop.shopImages && shop.shopImages.length > 0 && (
                        <img
                          src={shop.shopImages[0].img_url}
                          alt={shop.company_name}
                          className="shop-image"
                        />
                      )}
                      <p className="text-block text-[18px] font-bold">
                        {capitalizeWords(shop.company_name)}
                      </p>
                      <p>{capitalizeWords(shop.address)}</p>
                      <div className="flex items-center gap-2">
                        <img src={locationIcon} alt="Location Icon" />
                        <p>{shop.distance.toFixed(1)} km</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <img src={NoShopAvl} alt="No shop available" />
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default Shops;
