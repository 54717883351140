import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoMobile from "../assets/logoMobile.svg";
import shopArrow from "../assets/shopArrow.svg";
import rightArrow from "../assets/rightArrow.svg";
import downArrow from "../assets/downArrow.svg";
import search from "../assets/search.svg";
import location from "../assets/location.svg";
import { Link } from "react-router-dom";
import { ApiEndPoints, Constants } from "../Environment";
import slideArrowLeft from "../assets/slideArrowLeft.svg";
import slideArrowRight from "../assets/slideArrowRight.svg";
import NoShopAvl from "../assets/NoShopAvl.svg";
import NoProductAvl from "../assets/NoProductAvl.svg";
import NoImageAvl from "../assets/NoImageAvl.svg";
import { Modal, Input, Button } from "antd";
import MyLocImg from "../assets/MyLocImg.svg";
import Location from "../assets/locationBtn.svg";
import Login from "../assets/loginBtn.svg";
import { Tooltip } from "antd";
import FilterGray from "../assets/filterGray.svg";
import FilterWhite from "../assets/filterWhite.svg";
import MenuBar from "../assets/Menu-bar.svg";
// import { GoogleLogin } from "@react-oauth/google";
// import { GoogleLogout } from "@react-oauth/google";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  googleLogout,
} from "@react-oauth/google";

const Home = () => {
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchShopText, setSearchShopText] = useState("");
  const [shops, setShops] = useState([]);
  const [mobileShops, setMobileShops] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchshops, setSearchshops] = useState([]);
  const [error, setError] = useState(null);
  const [isSearchPopupVisible, setIsSearchPopupVisible] = useState(false);
  const [isSearchingShops, setIsSearchingShops] = useState(false); // New state for search mode
  const [recentProducts, setRecentProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  // State variables for button visibility
  const [showRightButton, setShowRightButton] = useState(true);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButtonShops, setShowRightButtonShops] = useState(true);
  const [showLeftButtonShops, setShowLeftButtonShops] = useState(false);
  const [showRightButtonRecent, setShowRightButtonRecent] = useState(true);
  const [showLeftButtonRecent, setShowLeftButtonRecent] = useState(false);
  const scrollContainer = useRef(null);
  const scrollContainerShops = useRef(null);
  const scrollContainerRecent = useRef(null);
  // Model Popup state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoginVisible, setIsModalLoginVisible] = useState(false);
  const [locationName, setLocationName] = useState("Location");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLoginSuccess = (credentialResponse) => {
    console.log(credentialResponse);
    setIsLoggedIn(true); // Set the state to true on successful login
  };

  const handleLoginFailure = () => {
    console.log("Login Failed");
  };

  const handleLogout = () => {
    googleLogout(); // Sign out from Google

    // Clear any user-related data stored in localStorage
    localStorage.removeItem("userEmail");

    // Optionally clear other localStorage items if needed
    localStorage.removeItem("someOtherItem");

    // Clear session storage
    sessionStorage.clear();

    // Clear cookies related to the Google session (if any)
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });

    setIsLoggedIn(false); // Set state to false
    console.log("Logout Successful and user data cleared");
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Login Modal
  const showModalLogin = () => {
    setIsModalLoginVisible(true);
  };

  const handleLoginOk = () => {
    setIsModalLoginVisible(false);
  };

  const handleLoginCancel = () => {
    setIsModalLoginVisible(false);
  };

  function capitalizeWords(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  // Function to handle scrolling to the right
  const scrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  // Function to handle scrolling to the left
  const scrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  // Function to update button visibility based on scroll position
  const updateButtonVisibility = () => {
    if (scrollContainer.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    }
  };

  // Add scroll event listener to update button visibility
  useLayoutEffect(() => {
    const container = scrollContainer.current;
    if (container) {
      container.addEventListener("scroll", updateButtonVisibility);
      updateButtonVisibility(); // Initial check

      return () => {
        container.removeEventListener("scroll", updateButtonVisibility);
      };
    }
  }, [products]); // Run effect when products change

  // Shops Scroll Function
  const scrollRightShops = () => {
    if (scrollContainerShops.current) {
      scrollContainerShops.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const scrollLeftShops = () => {
    if (scrollContainerShops.current) {
      scrollContainerShops.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const updateButtonVisibilityShops = () => {
    if (scrollContainerShops.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerShops.current;
      setShowLeftButtonShops(scrollLeft > 0);
      setShowRightButtonShops(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useLayoutEffect(() => {
    const container = scrollContainerShops.current;
    if (container) {
      container.addEventListener("scroll", updateButtonVisibilityShops);
      updateButtonVisibilityShops(); // Initial check

      return () => {
        container.removeEventListener("scroll", updateButtonVisibilityShops);
      };
    }
  }, [mobileShops]); // Run effect when products change

  // Recently products Scroll Function
  const scrollRightRecent = () => {
    if (scrollContainerRecent.current) {
      scrollContainerRecent.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const scrollLeftRecent = () => {
    if (scrollContainerRecent.current) {
      scrollContainerRecent.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const updateButtonVisibilityRecent = () => {
    if (scrollContainerRecent.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRecent.current;
      setShowLeftButtonRecent(scrollLeft > 0);
      setShowRightButtonRecent(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useLayoutEffect(() => {
    const container = scrollContainerRecent.current;
    if (container) {
      container.addEventListener("scroll", updateButtonVisibilityRecent);
      updateButtonVisibilityRecent(); // Initial check

      return () => {
        container.removeEventListener("scroll", updateButtonVisibilityRecent);
      };
    }
  }, [recentProducts]); // Run effect when products change

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchTextChangeShop = (event) => {
    setSearchShopText(event.target.value);
  };

  const handleFilterClick = () => {
    setIsSearchPopupVisible(!isSearchPopupVisible);
    setIsSearchingShops(!isSearchingShops); // Toggle search mode
  };

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const location = { latitude, longitude };
          setCurrentLocation(location);
          localStorage.setItem("currentLocation", JSON.stringify(location));
          fetchShops(latitude, longitude); // Fetch shops immediately

          // Use reverse geocoding to get location name
          const locationName = await getLocationNameFromCoordinates(
            latitude,
            longitude
          );
          setLocationName(locationName); // Update the location name
        },
        (error) => {
          console.error("Error fetching location: ", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const savedLocation = localStorage.getItem("currentLocation");
    if (savedLocation) {
      const { latitude, longitude } = JSON.parse(savedLocation);
      setCurrentLocation({ latitude, longitude });
      fetchShops(latitude, longitude); // Fetch shops using saved location
    } else {
      fetchCurrentLocation(); // Fetch current location and shops
    }

    fetchTrendingProducts(); // Always fetch trending products
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform logout actions or clearing data here
      localStorage.removeItem("currentLocation");
      setCurrentLocation(null);

      // If you want to prevent the default behavior (which is a prompt in some browsers)
      // event.preventDefault();
      // return "Are you sure you want to leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getLocationNameFromCoordinates = async (latitude, longitude) => {
    try {
      const apiKey = "AIzaSyCgvP_edprT87YWs4r9Hdxd6tJPS6lwQN8";
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      console.log("API Response:", response.data); // Log the entire API response

      const { results, status, error_message } = response.data;
      if (status === "OK" && results.length > 0) {
        return results[0].formatted_address;
      } else {
        console.error("Geocoding API error: ", status, error_message);
        return "Unknown Location";
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
      return "Unknown Location";
    }
  };

  const fetchShops = async (latitude, longitude) => {
    const payload = {
      latitude,
      longitude,
      kms: 10,
    };
    try {
      const response = await axios.post(
        Constants.BaseURL + ApiEndPoints.nearbyshops,
        payload
      );
      setShops(response.data);
      const filteredMobileShops = response.data.filter(
        (shop) => shop.company_type === 1
      );
      setMobileShops(filteredMobileShops);
    } catch (error) {
      console.error("Error fetching shops: ", error.response || error.message);
    }
  };

  const fetchProducts = async (searchText) => {
    const payload = {
      latitude: currentLocation?.latitude || 0,
      longitude: currentLocation?.longitude || 0,
      brand: searchText,
      is_new: "",
    };
    try {
      const response = await axios.post(
        Constants.BaseURL_Product + ApiEndPoints.Seacrch_products,
        payload
      );
      setProducts(response?.data?.data);
      navigate("/products", { state: { products: response?.data?.data } });
    } catch (error) {
      console.error(
        "Error fetching products: ",
        error.response || error.message
      );
    }
  };

  const fetchSearchShops = async (searchShopText) => {
    const payload = {
      latitude: currentLocation?.latitude || 0,
      longitude: currentLocation?.longitude || 0,
      search_text: searchShopText, // Ensure this parameter is being sent
      is_new: "",
    };
    try {
      const response = await axios.post(
        Constants.BaseURL + ApiEndPoints.Seacrch_shops,
        payload
      );
      setSearchshops(response?.data?.data); // Update the search shops state with the filtered data
      console.log(response?.data?.data, "searchshops");
      navigate("/shops", { state: { searchshops: response?.data?.data } });
    } catch (error) {
      console.error(
        "Error fetching searchshops: ",
        error.response || error.message
      );
    }
  };

  const fetchTrendingProducts = async () => {
    try {
      const response = await axios.get(
        Constants.BaseURL_Product + ApiEndPoints.Trending_Products
      );
      setProducts(response.data || []);
    } catch (error) {
      console.error(
        "Error fetching trending products: ",
        error.response || error.message
      );
    }
  };

  useEffect(() => {
    fetchTrendingProducts();
  }, []);

  const handleSearch = () => {
    if (isSearchingShops) {
      fetchSearchShops(searchShopText);
    } else {
      fetchProducts(searchText);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchClick = () => {
    handleSearch();
  };

  const handleShopClick = async (companyId, personId) => {
    try {
      const url = `${Constants.BaseURL}${ApiEndPoints.getCompanyProfile(
        companyId,
        personId
      )}`;
      const response = await axios.post(url, {
        latitude: 12.001,
        longitude: 14.001,
      });

      if (response?.data) {
        navigate(`/shopProfile/${companyId}/${personId}`, {
          state: {
            shopProfile: {
              ...response.data,
              company_id: companyId,
              personId: personId,
            },
          },
        });
      } else {
        console.error("No data received from the API.");
      }
    } catch (error) {
      console.error(
        "Error fetching shop profile: ",
        error.response || error.message
      );
      setError("Error fetching shop profile. Please try again later.");
    }
  };

  // Recent Products API integration
  useEffect(() => {
    const fetchRecentProducts = async () => {
      try {
        const response = await axios.post(
          "https://erpserver.tazk.in/locstoProduct/recentproducts?page=0&per_page=100",
          {
            latitude: 12.9755,
            longitude: 80.2207,
            kms: 10,
            is_new: "",
          }
        );
        setRecentProducts(response?.data);
        console.log(response?.data, "Recent products");
      } catch (err) {
        setError(err);
      } finally {
        // setLoading(false);
      }
    };

    fetchRecentProducts();
  }, []);

  /* Top Brands */
  useEffect(() => {
    const fetchTrendingBrands = async () => {
      try {
        const response = await axios.get(
          "https://erpserver.tazk.in/locstoProduct/trendingBrands"
        );
        setBrands(response?.data);
        console.log(response?.data, "Top Brands");
      } catch (err) {
        setError(err);
      } finally {
        // setLoading(false);
      }
    };
    fetchTrendingBrands();
  }, []);

  const trendProductsSearch = async (brand_name) => {
    const payload = {
      latitude: currentLocation?.latitude || 0,
      longitude: currentLocation?.longitude || 0,
      brand: brand_name,
      is_new: "",
    };
    try {
      const response = await axios.post(
        Constants.BaseURL_Product + ApiEndPoints.Seacrch_products,
        payload
      );
      setProducts(response?.data?.data);
      navigate("/products", { state: { products: response?.data?.data } });
    } catch (error) {
      console.error(
        "Error fetching products: ",
        error.response || error.message
      );
    }
  };

  useEffect(() => {
    // Fetch and set brands here
  }, []);

  useEffect(() => {
    const fetchBookmarks = async () => {
      try {
        const payload = {
          latitude: 12.7879152,
          longitude: 80.2209351,
        };

        const response = await axios.post(
          "https://erpserver.tazk.in/locstoProduct/bookmark/157?page=0&per_page=100",
          payload
        );

        setBookmarks(response?.data || []); // Safely access data
        console.log(response?.data, "Bookmark products");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
        setError("Error fetching bookmarks. Please try again later.");
        // setLoading(false);
      }
    };

    fetchBookmarks();
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <>
      <div className="homePage">
        <header className="h-[269px] xl:px-20 md:px-16 pt-4 bg bg-cover bg-center bg-no-repeat bannerMobile">
          <div className="flex justify-between items-center headerMobile">
            <div className="flex xl:gap-16 md:gap-16 items-center locationMobile">
              <img
                className="cursor-pointer logoDesktop"
                src={logo}
                alt="Logo"
                onClick={() => window.location.reload()}
              />
              <img
                className="cursor-pointer logoMobile"
                src={logoMobile}
                alt="Logo"
                onClick={() => window.location.reload()}
              />
              <div>
                <div
                  onClick={showModal}
                  className="flex items-baseline gap-2 cursor-pointer p-2 loginDesktop"
                >
                  <div className="text-white text-base font-medium">
                    {/* {locationName} */}Location
                  </div>
                  <img src={downArrow} alt="Down Arrow" />
                </div>
                <Modal
                  title="Search Location"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null} // Remove default footer buttons
                >
                  <div className="flex flex-col gap-4">
                    <Input placeholder="Search location" className="p-2" />
                    <div
                      onClick={() => {
                        fetchCurrentLocation();
                        handleOk();
                      }}
                      className="flex border-b border-b-GrayBorder pb-2"
                    >
                      <img src={MyLocImg} alt="" />
                      <button
                        type="primary"
                        className="text-GrayLoc font-semibold p-2"
                      >
                        My Current Location
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="flex gap-4 loginPadding loginDesktop">
              {/* <div>
                <img
                  src={MenuBar}
                  alt="Menu Bar"
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {isDropdownOpen && (
                <ul className="text-white dropdown-menu">
                  <li>Home</li>
                  <li>Offers</li>
                  <li>Shops</li>
                  <li>Favorites</li>
                  <li>Account</li>
                </ul>
              )} */}
              <button
                onClick={showModalLogin}
                className="flex justify-center items-center w-[112px] h-[40px] bg-white gap-2 rounded-[100px] homeLogin"
              >
                <a className="text-base font-semibold uppercase" href="#login">
                  Login
                </a>
                <img src={rightArrow} alt="Right Arrow" />
              </button>
            </div>
            <div className="flex gap-6 locMobile">
              <img
                onClick={showModal}
                className=""
                src={Location}
                alt="Location"
              />
              <img
                onClick={showModalLogin}
                className=""
                src={Login}
                alt="Login"
              />
            </div>
          </div>
          <Modal
            title="Login"
            visible={isModalLoginVisible}
            onOk={handleLoginOk}
            onCancel={handleLoginCancel}
            footer={null}
          >
            <form>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Email</label>
                <input
                  type="email"
                  className="w-full p-2 border rounded"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Password</label>
                <input
                  type="password"
                  className="w-full p-2 border rounded"
                  placeholder="Enter your password"
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                <label className="inline-flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="ml-2 text-sm">Remember Me</span>
                </label>
                <a href="#" className="text-sm text-blue-500 hover:underline">
                  Forgot Password?
                </a>
              </div>
              <button
                type="submit"
                className="w-full p-2 bg-blue-500 text-white rounded"
              >
                Login
              </button>
              {/* <GoogleOAuthProvider clientId="447844514848-c2cd5ndtk7irjptbplj37monhj0nckqn.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    console.log(credentialResponse);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </GoogleOAuthProvider> */}
              <div className="mt-6">
                <GoogleOAuthProvider clientId="447844514848-c2cd5ndtk7irjptbplj37monhj0nckqn.apps.googleusercontent.com">
                  {!isLoggedIn ? (
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      onError={handleLoginFailure}
                    />
                  ) : (
                    <button onClick={handleLogout}>Logout</button>
                  )}
                </GoogleOAuthProvider>
              </div>
            </form>
          </Modal>
        </header>

        <div className="">
          {/* Search container */}
          <section className="search-container">
            <img
              className="cursor-pointer"
              onClick={handleSearchClick}
              src={search}
              alt="Search"
            />
            <input
              type="text"
              className="search-input"
              placeholder={
                isSearchingShops
                  ? "Search your shop here"
                  : "Search your product here"
              }
              value={isSearchingShops ? searchShopText : searchText}
              onChange={
                isSearchingShops
                  ? handleSearchTextChangeShop
                  : handleSearchTextChange
              }
              onKeyDown={handleKeyDown}
            />
            <img
              src={isSearchingShops ? FilterWhite : FilterGray}
              onClick={handleFilterClick}
              className={`mr-2 cursor-pointer ${
                isSearchingShops ? "bg-primary" : ""
              } p-2 rounded-lg`}
              alt="Filter"
            />
          </section>

          {/* Nearby Shops */}
          <section className="xl:px-20 md:px-16 xl:pt-10 sectionHeight">
            <div className="flex gap-2">
              <h3>Nearby Shops</h3>
              <img className="" src={shopArrow} alt="Shop Arrow" />
            </div>
            {mobileShops && mobileShops.length > 0 ? (
              <div className="relative">
                <ul
                  className="shop-list pt-6 flex gap-2 overflow-hidden"
                  ref={scrollContainerShops}
                >
                  {shops.map((shop) => (
                    <li
                      className="cursor-pointer"
                      key={shop.person_id}
                      onClick={() =>
                        handleShopClick(shop.company_id, shop.person_id)
                      }
                    >
                      <div className="shop-item">
                        {shop.shopImages && shop.shopImages.length > 0 && (
                          <img
                            className="shop-image"
                            src={shop.shopImages[0].img_url}
                            alt={shop.company_name}
                          />
                        )}
                        <div>
                          <p className="text-[16px] w-full">
                            {truncateString(
                              capitalizeWords(shop.company_name),
                              10
                            )}
                          </p>

                          <div className="flex items-center gap-2">
                            <img src={location} alt="Location Icon" />
                            <p className="text-gray text-[16px] font-normal">
                              {shop.distance.toFixed(1)} km
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {showRightButtonShops && (
                  <button
                    className="absolute right-0 top-0 bottom-[9%] flex items-center justify-center"
                    onClick={scrollRightShops}
                  >
                    <img src={slideArrowRight} alt="" />
                  </button>
                )}
                {showLeftButtonShops && (
                  <button
                    className="absolute left-0 top-0 bottom-[9%] flex items-center justify-center"
                    onClick={scrollLeftShops}
                  >
                    <img src={slideArrowLeft} alt="" />
                  </button>
                )}
              </div>
            ) : (
              <img src={NoShopAvl} alt="No Shops Available" />
            )}
          </section>

          {/* Top Brands */}
          <section className="xl:px-20 md:px-16 pt-6 sectionHeight">
            <h3>Trending Brands</h3>
            <div className="relative overflow-hidden">
              <div className="brand-container">
                {brands &&
                  brands.map((brand, index) => (
                    <div
                      key={index}
                      className="brand-card"
                      onClick={() => trendProductsSearch(brand.brand_name)} // Fetch products by brand name
                    >
                      <p className="brand-name">
                        {capitalizeWords(brand.brand_name)}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </section>

          {/* Display Trending Products */}
          <section className="xl:px-20 md:px-16 pt-6 sectionHeight">
            <div className="flex gap-2">
              <h3>Trending Products</h3>
              <img className="" src={shopArrow} alt="Trending Products Arrow" />
            </div>
            <div className="relative overflow-hidden">
              <div
                className="product-container flex gap-2 pt-6 overflow-hidden"
                ref={scrollContainer}
              >
                {products && products.length > 0 ? (
                  <ul className="Trending-product-list flex gap-2">
                    {products.map((product) => (
                      <Link
                        to={`/product/${product?.company_id}`}
                        state={{ product, products, is_avalible: true }}
                      >
                        <li
                          key={product.id}
                          className="home-product-item flex-none"
                        >
                          <div>
                            <p className="bg-black text-[10px] w-10 text-center text-white rounded-md">
                              {product?.is_new === 0
                                ? "Used"
                                : product?.is_new === 1
                                ? "New"
                                : ""}
                            </p>
                            <div className="product-images">
                              {product.product_images?.length > 0 ? (
                                <img
                                  className="product-thumbnail"
                                  src={product.product_images[0].img_url}
                                  alt={product.product_images[0].type}
                                />
                              ) : (
                                <img
                                  src={NoImageAvl}
                                  alt="No image available"
                                />
                              )}
                            </div>
                            <Tooltip title={capitalizeWords(product.name)}>
                              <p className="text-secondary text-balance font-bold text-[16px] py-2 break-words w-40">
                                {truncateString(
                                  capitalizeWords(product.name),
                                  10
                                )}
                              </p>
                            </Tooltip>
                            <p className="text-GreenRS font-medium text-[16px]">
                              ₹ {product.unit_price}
                            </p>
                          </div>
                        </li>
                      </Link>
                    ))}
                  </ul>
                ) : (
                  <img src={NoProductAvl} alt="No product available" />
                )}
              </div>
              {showRightButton && (
                <button
                  className="absolute right-0 top-0 bottom-[9%] flex items-center justify-center"
                  onClick={scrollRight}
                >
                  <img src={slideArrowRight} alt="" />
                </button>
              )}
              {showLeftButton && (
                <button
                  className="absolute left-0 top-0 bottom-[9%] flex items-center justify-center"
                  onClick={scrollLeft}
                >
                  <img src={slideArrowLeft} alt="" />
                </button>
              )}
            </div>
          </section>

          {/* Shortlisted Products */}
          <section className="xl:px-20 md:px-16 pt-6 sectionHeight">
            <div className="flex gap-2">
              <h3>Shortlisted</h3>
              <img className="" src={shopArrow} alt="Trending Products Arrow" />
            </div>
            <div className="product-container flex gap-2 pt-6 overflow-hidden">
              {bookmarks && bookmarks.length > 0 ? (
                <ul className="Trending-product-list flex gap-2">
                  {bookmarks.map((product) => (
                    <Link
                      to={`/product/${product.company_id}`}
                      state={{
                        product,
                        products: bookmarks,
                        is_avalible: true,
                      }}
                      key={product.id}
                    >
                      <li className="home-product-item flex-none">
                        <div>
                          <p className="bg-black text-[10px] w-10 text-center text-white rounded-md">
                            {product.is_new === 0
                              ? "Used"
                              : product.is_new === 1
                              ? "New"
                              : ""}
                          </p>
                          <div className="product-images">
                            {product.product_images?.length > 0 ? (
                              <img
                                className="product-thumbnail"
                                src={product.product_images[0].img_url}
                                alt={product.product_images[0].type}
                              />
                            ) : (
                              <img src={NoImageAvl} alt="No image available" />
                            )}
                          </div>
                          <p className="text-secondary text-balance font-bold text-[16px] py-2 break-words w-40">
                            {truncateString(capitalizeWords(product.name), 10)}
                          </p>
                          <p className="text-GreenRS font-medium text-[16px]">
                            ₹ {product.unit_price}
                          </p>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              ) : (
                <img src={NoProductAvl} alt="No product available" />
              )}
            </div>
          </section>
          {/* Recent Products */}
          <section className="xl:px-20 md:px-16 xl:pt-6 sectionHeight">
            <div className="flex gap-2">
              <h3>Recently Added</h3>
              <img className="" src={shopArrow} alt="Trending Products Arrow" />
            </div>
            <div className="relative overflow-hidden">
              <div
                className="product-container flex gap-2 pt-6 overflow-hidden"
                ref={scrollContainerRecent}
              >
                {recentProducts && recentProducts.length > 0 ? (
                  <ul className="Trending-product-list flex gap-2">
                    {recentProducts.map((product) => (
                      <Link
                        to={`/product/${product?.company_id}`}
                        state={{ product, products, is_avalible: true }}
                      >
                        <li
                          key={product.id}
                          className="home-product-item flex-none"
                        >
                          <div>
                            <div className="product-images">
                              {product.product_images?.length > 0 ? (
                                <img
                                  className="product-thumbnail"
                                  src={product.product_images[0].img_url}
                                  alt={product.product_images[0].type}
                                />
                              ) : (
                                <img
                                  src={NoImageAvl}
                                  alt="No image available"
                                />
                              )}
                            </div>
                            <Tooltip title={capitalizeWords(product.name)}>
                              <p className="text-secondary text-balance font-bold text-[16px] py-2 break-words w-40">
                                {truncateString(
                                  capitalizeWords(product.name),
                                  10
                                )}
                              </p>
                            </Tooltip>
                            <p className="text-GreenRS font-medium text-[16px]">
                              ₹ {product.unit_price} /-
                            </p>
                          </div>
                        </li>
                      </Link>
                    ))}
                  </ul>
                ) : (
                  <img src={NoProductAvl} alt="No product available" />
                )}
              </div>
              {showRightButtonRecent && (
                <button
                  className="absolute right-0 top-0 bottom-[9%] flex items-center justify-center"
                  onClick={scrollRightRecent}
                >
                  <img src={slideArrowRight} alt="" />
                </button>
              )}
              {showLeftButtonRecent && (
                <button
                  className="absolute left-0 top-0 bottom-[9%] flex items-center justify-center"
                  onClick={scrollLeftRecent}
                >
                  <img src={slideArrowLeft} alt="" />
                </button>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Home;
