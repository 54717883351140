import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoMobile from "../assets/logoMobile.svg";
import downArrow from "../assets/downArrow.svg";
import rightArrow from "../assets/rightArrow.svg";
import shopArrow from "../assets/shopArrow.svg";
import AddressIcon from "../assets/addressIcon.svg";
import Member from "../assets/memberIcon.svg";
import Follow from "../assets/FollowIcon.svg";
import Followers from "../assets/FollowersIcon.svg";
import Google from "../assets/google.svg";
import Gmail from "../assets/Gmail.svg";
import WhatsappIcon from "../assets/whatsappIcon.svg";
import search from "../assets/search.svg";
import { ApiEndPoints, Constants } from "../Environment";
import NoProductAvl from "../assets/NoProductAvl.svg";
import Location from "../assets/locationBtn.svg";
import Login from "../assets/loginBtn.svg";
import slideArrowLeft from "../assets/slideArrowLeft.svg";
import slideArrowRight from "../assets/slideArrowRight.svg";

const SearchShopProfile = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { shopProfile: initialProducts } = location.state || {};
  const [shopProfile, setShopProfile] = useState(initialProducts || []);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  // State variables for button visibility
  const [showRightButton, setShowRightButton] = useState(true);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const scrollContainer = useRef(null);

  const userId = 157;
  const companyId = shopProfile?.company_id;

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    fetchProducts(searchText);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchClick = () => {
    handleSearch();
  };

  // Function to handle scrolling to the right
  const scrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  // Function to handle scrolling to the left
  const scrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  // Function to update button visibility based on scroll position
  const updateButtonVisibility = () => {
    if (scrollContainer.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    }
  };

  // Add scroll event listener to update button visibility
  useLayoutEffect(() => {
    const container = scrollContainer.current;
    if (container) {
      container.addEventListener("scroll", updateButtonVisibility);
      updateButtonVisibility(); // Initial check

      return () => {
        container.removeEventListener("scroll", updateButtonVisibility);
      };
    }
  }, [products]); // Run effect when products change

  const fetchProducts = async (searchText) => {
    const payload = {
      latitude: currentLocation?.latitude || 0,
      longitude: currentLocation?.longitude || 0,
      brand: searchText,
      is_new: "",
    };

    try {
      const response = await axios.post(
        Constants.BaseURL_Product + ApiEndPoints.Seacrch_products,
        payload
      );
      setProducts(response?.data?.data); // Adjust according to the actual response structure
      console.log(response?.data?.data, "setProducts");
      navigate("/products", { state: { products: response?.data?.data } });
    } catch (error) {
      console.error(
        "Error fetching products: ",
        error.response || error.message
      );
    }
  };

  useEffect(() => {
    if (location.state?.shopProfile) {
      setShopProfile(location.state.shopProfile);
      console.log(location.state.shopProfile, "shop profile pick"); // Log the shopProfile to see its structure
    }
  }, [location.state]);

  useEffect(() => {
    const fetchShopProducts = async () => {
      if (companyId) {
        try {
          const url = `${Constants.BaseURL_Product}${ApiEndPoints.getItemsUrl(
            companyId
          )}`;
          const response = await axios.post(url, {
            categories: ["SMARTPHONE"],
          });
          setProducts(response.data || []);
        } catch (error) {
          setError("Error fetching shop products. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchShopProducts();
  }, [companyId]);

  const handleFollow = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        Constants.BaseURL + ApiEndPoints.Follow_profile,
        {
          following_id: userId,
          followed_id: companyId,
        }
      );
      setIsFollowing(true);
    } catch (error) {
      setError("Failed to follow. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleProductClick = (product) => {
    navigate(`/product/${product.item_id}`, {
      state: { product, products, is_avalible: false },
    });
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text, limit) => {
    if (typeof text !== "string") {
      return "";
    }
    if (text.length <= limit) {
      return text;
    }
    return text.substring(0, limit) + "...";
  };

  const addressLimit = 25;

  if (!shopProfile) {
    return <div>Loading...</div>;
  }

  function capitalizeWords(str) {
    if (typeof str !== "string") {
      return "";
    }
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      <header className="header-fixed xl:px-20 md:px-16 bg-primary xl:flex xl:justify-between md:flex md:justify-between items-center heightMobile">
        <div className="flex xl:gap-16 md:gap-16 items-center logoLocation">
          <img
            className="cursor-pointer logoDesktop"
            src={logo}
            alt="Logo"
            onClick={() => navigate("/")}
          />
          <img
            className="cursor-pointer logoMobile"
            src={logoMobile}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <div className="flex items-baseline gap-2 cursor-pointer loginDesktop">
            <div className="text-white text-base font-medium">Location</div>
            <img src={downArrow} alt="Down Arrow" />
          </div>
          <div className="flex gap-6">
            <img className="locMobile" src={Location} alt="Location" />
            <img className="locMobile" src={Login} alt="Login" />
          </div>
        </div>
        <div className="search-container-product">
          <input
            type="text"
            className="search-input"
            placeholder="Search your product here"
            value={searchText}
            onChange={handleSearchTextChange}
            onKeyDown={handleKeyDown}
          />
          <img
            className="cursor-pointer"
            onClick={handleSearchClick}
            src={search}
            alt="Search"
          />
        </div>
        <div className="loginPadding loginDesktop">
          <button className="flex justify-center items-center w-[112px] h-[48px] bg-white gap-2 rounded-[100px] loginMobile">
            <a className="text-base font-semibold uppercase">Login</a>
            <img src={rightArrow} alt="Right Arrow" />
          </button>
        </div>
      </header>

      <main className="content shopPopup">
        <section>
          <div className="">
            {shopProfile?.shopImages?.length > 0 && (
              <img
                className="m-auto w-[80%] h-[300px] bg-cover object-fill rounded-[8px] shopImgMobile"
                src={shopProfile.shopImages[0].img_url}
                alt={shopProfile.shopImages[0].img_name}
              />
            )}
          </div>

          <div className="relative">
            <div className="xl:flex lg:flex items-center justify-between h-[35vh] overflow-y-auto gap-10 boxShadow absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-md shadow-lg">
              <div className="xl:w-[55%] lg:w-[55%]">
                <p className="text-primary font-semibold xl:text-[24px] md:text-[20px] text-[20px]">
                  {capitalizeWords(shopProfile?.company_name)}
                </p>
                <div className="flex gap-2 xl:pt-4 md:pt-4 pt-2">
                  <img src={Member} />
                  <p>Member Since Dec 2023</p>
                </div>
                <div className="flex gap-6 xl:pt-4 md:pt-4 pt-2">
                  <div className="flex gap-2">
                    <img src={Followers} />
                    <p className="text-block font-medium text-[14px]">
                      216 Followers
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img src={Follow} />
                    <p className="text-block font-medium text-[14px]">
                      24 Following
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex gap-2 text-[#828282] xl:pt-4 md:pt-4 pt-2">
                    <img src={AddressIcon} alt="Address Icon" />
                    <p className="xl:w-auto xl:text-balance">
                      {isExpanded
                        ? capitalizeWords(shopProfile?.address || "")
                        : truncateText(
                            capitalizeWords(shopProfile?.address || ""),
                            addressLimit
                          )}
                      {shopProfile?.address?.length > addressLimit && (
                        <span
                          onClick={handleToggle}
                          className="text-blue-500 cursor-pointer"
                        >
                          {isExpanded ? " less" : " more"}
                        </span>
                      )}
                    </p>
                  </div>
                  {shopProfile?.near_location && (
                    <p className="pt-2">Near: {shopProfile.near_location}</p>
                  )}
                </div>
              </div>
              <div className="pt-2 xl:w-[30%] lg:w-[30%]">
                <p className="text-primary font-medium">
                  User Verified With You
                </p>
                <div className="flex gap-12 py-2">
                  <img className="cursor-pointer" src={Google} />
                  <img className="cursor-pointer" src={Gmail} />
                  <img className="cursor-pointer" src={WhatsappIcon} />
                </div>
              </div>
              <div className="xl:pt-2 xl:w-[10%] lg:w-[10%]">
                <button
                  onClick={handleFollow}
                  disabled={loading || isFollowing}
                  className="mt-4 px-4 py-2 text-primary rounded-[22px] border-[1px]"
                >
                  {loading
                    ? "Following..."
                    : isFollowing
                    ? "Following"
                    : "Follow"}
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
            </div>
          </div>
        </section>

        <section className="xl:px-20 md:px-16 md:mt-48 mt-10 pb-10 sectionHeight sectionPadding">
          <div>
            <div className="flex gap-2">
              <h3>Products</h3>
              <img className="" src={shopArrow} alt="Shop Arrow" />
            </div>
            {loading ? (
              <p>Loading shop products...</p>
            ) : error ? (
              <div>{error}</div>
            ) : products?.length > 0 ? (
              <div className="relative">
                <ul
                  className="product-list-container pt-6"
                  ref={scrollContainer}
                >
                  {products.map((product) => (
                    <li
                      className="cursor-pointer mb-4"
                      key={product.item_id}
                      onClick={() => handleProductClick(product)}
                    >
                      <div>
                        {product.product_images?.length > 0 && (
                          <img
                            src={product.product_images[0]?.img_url}
                            alt={product.name}
                            className=""
                          />
                        )}
                      </div>
                      <div className="pt-4">
                        <h4 className="text-secondary font-bold text-[16px]">
                          {capitalizeWords(product.name)}
                        </h4>
                        <p className="text-[16px]">
                          Brand: {capitalizeFirstLetter(product.brand)}
                        </p>
                        <div className="text-GreenRS font-medium text-[16px]">
                          ₹ {product.unit_price}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {showRightButton && (
                  <button
                    className="absolute right-0 top-0 bottom-[9%] flex items-center justify-center"
                    onClick={scrollRight}
                  >
                    <img src={slideArrowRight} alt="" />
                  </button>
                )}
                {showLeftButton && (
                  <button
                    className="absolute left-0 top-0 bottom-[9%] flex items-center justify-center"
                    onClick={scrollLeft}
                  >
                    <img src={slideArrowLeft} alt="" />
                  </button>
                )}
              </div>
            ) : (
              <img src={NoProductAvl} alt="No product available" />
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default SearchShopProfile;
