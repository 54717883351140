export const ROUTES = {
  PRODUCT: "/products",
  SHOPS: "/shops",
  PRODUCTDETAILS: "/productdetails",
  MOBILEDETAILS: "/mobile/:mobileId",
};

export const SESSION_NAME = {
  ROLE: "role",
  USER: "USER",
  USERID: "userId",
  USERNAME: "Username",
  EMAIL: "email",
  PASSWORD: "Password",
};
