import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoMobile from "../assets/logoMobile.svg";
import downArrow from "../assets/downArrow.svg";
import rightArrow from "../assets/rightArrow.svg";
import shopArrow from "../assets/shopArrow.svg";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import carKM from "../assets/car.svg";
import whatsapp from "../assets/whatsapp.svg";
import callBTN from "../assets/call_Icon.svg";
import messageBTN from "../assets/message_Icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import search from "../assets/search.svg";
import BackArrow from "../assets/BackArrow.svg";
import { ApiEndPoints, Constants } from "../Environment";
import { Modal } from "antd";
import NoImageAvl from "../assets/NoImageAvl.svg";
import Bookmark from "../assets/bookmark.svg";
import Location from "../assets/locationBtn.svg";
import Login from "../assets/loginBtn.svg";
import slideArrowLeft from "../assets/slideArrowLeft.svg";
import slideArrowRight from "../assets/slideArrowRight.svg";
import { Tooltip } from "antd";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";

const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { product, products: initialProducts } = location.state || {};
  const [searchText, setSearchText] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [products, setProducts] = useState(initialProducts);
  const [error, setError] = useState(null); // Add error state
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [phoneNumberToCopy, setPhoneNumberToCopy] = useState("");
  // State variables for button visibility
  const [showRightButton, setShowRightButton] = useState(true);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const scrollContainer = useRef(null);
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    fetchProducts(searchText);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchClick = () => {
    handleSearch();
  };

  function capitalizeWords(str) {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  // const toggleBookmark = () => {
  //   setIsBookmarked(!isBookmarked);
  // };

  // Function to handle scrolling to the right
  const scrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  // Function to handle scrolling to the left
  const scrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  // Function to update button visibility based on scroll position
  const updateButtonVisibility = () => {
    if (scrollContainer.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    }
  };

  // Add scroll event listener to update button visibility
  useLayoutEffect(() => {
    const container = scrollContainer.current;
    if (container) {
      container.addEventListener("scroll", updateButtonVisibility);
      updateButtonVisibility(); // Initial check

      return () => {
        container.removeEventListener("scroll", updateButtonVisibility);
      };
    }
  }, [products]); // Run effect when products change

  const fetchProducts = async (searchText) => {
    const payload = {
      latitude: currentLocation?.latitude || 0,
      longitude: currentLocation?.longitude || 0,
      brand: searchText,
      is_new: "",
    };

    try {
      const response = await axios.post(
        Constants.BaseURL_Product + ApiEndPoints.Seacrch_products,
        payload
      );
      setProducts(response?.data?.data); // Adjust according to the actual response structure
      console.log(response?.data?.data, "setProducts");
      navigate("/products", { state: { products: response?.data?.data } });
    } catch (error) {
      console.error(
        "Error fetching products: ",
        error.response || error.message
      );
    }
  };

  useEffect(() => {
    if (product) {
      console.log("Product data:", product);
    }
  }, [product]);

  if (!product) {
    return <p>Product not found</p>;
  }

  const getProductImages = (productImages) => {
    if (!productImages || productImages.length === 0) {
      return [];
    }
    return productImages.map((image) => ({
      original:
        image.img_url || `https://yourimagebaseurl.com/${image.image_path}`,
      thumbnail:
        image.img_url || `https://yourimagebaseurl.com/${image.image_path}`,
    }));
  };

  const phoneNumber = product.bussiness_whatsapp_number;

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const whatsappUrl = isMobileDevice()
    ? `https://wa.me/${phoneNumber}`
    : `https://web.whatsapp.com/send?phone=${phoneNumber}`;

  const handleShopClick = async (companyId, personId) => {
    try {
      const url = `${Constants.BaseURL}${ApiEndPoints.getCompanyProfile(
        companyId,
        personId
      )}`;
      const response = await axios.post(url, {
        latitude: 12.001,
        longitude: 14.001,
      });
      console.log("Shop Profile Response:", response?.data); // Log the response data
      console.log("personId", personId); // Log the response data

      if (response?.data) {
        // Ensure company_id is included in the shopProfile
        navigate(`/shopProfile/${companyId}/${personId}`, {
          state: {
            shopProfile: {
              ...response.data,
              company_id: companyId,
              personId: personId,
            },
          },
        });
      } else {
        console.error("No data received from the API.");
      }
    } catch (error) {
      console.error(
        "Error fetching shop profile: ",
        error.response || error.message
      );
      setError("Error fetching shop profile. Please try again later.");
    }
  };

  const handleBackClick = () => {
    navigate(-1); // This navigates back to the previous page
  };

  const handleCopyPhoneNumber = (phoneNumber) => {
    navigator.clipboard
      .writeText(phoneNumber)
      .then(() => {
        setPhoneNumberToCopy(phoneNumber);
        setIsModalVisible(true);
      })
      .catch((error) => {
        console.error("Error copying phone number: ", error);
      });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  // Bookmark code
  // Updated toggleBookmark function
  const toggleBookmark = async () => {
    const newBookmarkStatus = !isBookmarked;

    // Ensure itemId and userId are properly defined before using them
    const itemId = product.item_id; // Replace with the correct field for item ID
    const userId = 157; // Replace with the actual user ID

    try {
      let response;
      if (newBookmarkStatus) {
        // POST method to add the bookmark
        response = await fetch(
          "https://erpserver.tazk.in/locstoProduct/bookmark",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              item_id: itemId,
              user_id: userId,
            }),
          }
        );
      } else {
        // DELETE method to remove the bookmark
        response = await fetch(
          `https://erpserver.tazk.in/locstoProduct/bookmark/${userId}/${itemId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.ok) {
        // If the API call is successful, update the bookmark state
        setIsBookmarked(newBookmarkStatus);
      } else {
        console.error("Failed to toggle bookmark:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while toggling bookmark:", error);
    }
  };

  return (
    <>
      <header className="header-fixed xl:px-20 md:px-16 bg-primary xl:flex xl:justify-between md:flex md:justify-between items-center heightMobile">
        <div className="flex xl:gap-16 md:gap-16 items-center logoLocation">
          <img
            className="cursor-pointer logoDesktop"
            src={logo}
            alt="Logo"
            onClick={() => navigate("/")}
          />
          <img
            className="cursor-pointer logoMobile"
            src={logoMobile}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <div className="flex items-baseline gap-2 cursor-pointer loginDesktop">
            <div className="text-white text-base font-medium">Location</div>
            <img src={downArrow} alt="Down Arrow" />
          </div>
          <div className="flex gap-6">
            <img className="locMobile" src={Location} alt="Location" />
            <img className="locMobile" src={Login} alt="Login" />
          </div>
        </div>
        <div className="search-container-product">
          <input
            type="text"
            className="search-input"
            placeholder="Search your product here"
            value={searchText}
            onChange={handleSearchTextChange}
            onKeyDown={handleKeyDown}
          />
          <img
            className="cursor-pointer"
            onClick={handleSearchClick}
            src={search}
            alt="Search"
          />
        </div>
        <div className="loginPadding loginDesktop">
          <button className="flex justify-center items-center w-[112px] h-[48px] bg-white gap-2 rounded-[100px] loginMobile">
            <a className="text-base font-semibold uppercase">Login</a>
            <img src={rightArrow} alt="Right Arrow" />
          </button>
        </div>
      </header>

      <main className="content">
        <section className="md:px-8 lg:px-16 xl:px-20">
          <button
            onClick={handleBackClick}
            className="flex items-center gap-2 py-4 xl:pt-0 md:pt-0 pt-16 backBtnMobile"
          >
            <img src={BackArrow} alt="Back" />
            <p className="font-medium">Back</p>
          </button>
          <div className="flex flex-col gap-10 xl:flex-row md:flex-row MobileImage">
            <div className="xl:w-1/2 sectionHeight xl:mb-0 xl:mr-6">
              {product.product_images && product.product_images.length > 0 ? (
                <ImageGallery
                  items={getProductImages(product.product_images)}
                  additionalClass="custom-gallery"
                />
              ) : (
                <img src={NoImageAvl} alt="No image available" />
              )}
            </div>
            <div className="xl:w-1/2 xl:py-10 md:py-10 sectionHeight">
              <p className="text-xl font-bold pb-2 uppercase">
                {/* {product.brand} */}
                {product.name}
              </p>
              <p className="text-[16px]">{product.description}</p>
              <p className="text-[16px]">{capitalizeWords(product.descript)}</p>
              <div className="flex gap-2">
                <p className="text-DarkGray text-[16px] font-normal">
                  Category:
                </p>
                <p className="">{capitalizeFirstLetter(product.category)}</p>
              </div>

              <p className="text-DarkGray text-[16px] font-normal">Ram: </p>
              <p className="text-DarkGray text-[16px] font-normal">
                Processor:
              </p>
              <p className="text-DarkGray text-[16px] font-normal">Camera: </p>
              <p className="font-bold text-GreenRS text-[16px] mb-2">
                Price ₹ {product.unit_price}
              </p>

              <div onClick={toggleBookmark} style={{ cursor: "pointer" }}>
                {isBookmarked ? <FaBookmark /> : <FaRegBookmark />}
              </div>
            </div>
          </div>
        </section>

        {location.state.is_avalible && (
          <section className="xl:px-20 md:px-16 xl:pt-10 md:pt-8 sectionHeight">
            <div className="flex gap-2 xl:py-0 md:py-0 py-4">
              <h3>Available Shops</h3>
              <img className="" src={shopArrow} alt="Shop Arrow" />
            </div>
            <div className="relative">
              <ul
                className="product-list-shops xl:mt-8 md:mt-6"
                ref={scrollContainer}
              >
                {products.map((item, index) => (
                  <li
                    key={item} // Use unique key
                    onClick={() => handleShopClick(item.company_id, 157)}
                    className="product-item-shops cursor-pointer"
                  >
                    <div className="flex justify-between">
                      <div className="product-image-shops relative">
                        {item.shopImages && item.shopImages.length > 0 && (
                          <img
                            src={item.shopImages[0].img_url}
                            alt={`Shop image ${index + 1}`}
                            className="rounded-image-shops"
                          />
                        )}
                      </div>
                      <div className="content-center">
                        <img
                          onClick={(e) => e.stopPropagation()}
                          className="m-auto"
                          src={Bookmark}
                          alt="Bookmark"
                        />
                        {location.state.is_Distance && (
                          <div className="flex gap-2 text-gray">
                            <img src={carKM} />
                            <p className="text-nowrap text-[14px]">
                              {parseInt(item?.distance).toFixed(1)} km
                              {console.log(item?.distance, "item?.distance")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="product-details-shops">
                      <div className="flex gap-4 text-nowrap">
                        <p className="text-block text-[18px] font-bold text-wrap">
                          {truncateString(
                            capitalizeWords(item.company_name),
                            20
                          )}
                        </p>
                      </div>
                      <p className="text-[16px]">
                        {truncateString(capitalizeWords(item.name), 20)}
                      </p>
                      <Tooltip title={capitalizeWords(item.address)}>
                        <p className="text-secondary text-balance font-bold text-[16px] py-2 break-words w-40">
                          {truncateString(capitalizeWords(item.address), 14)}
                        </p>
                      </Tooltip>
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="flex items-center gap-4 pt-2 w-[280px]"
                      >
                        <img className="" src={messageBTN} alt="callBTN" />
                        <a
                          href={whatsappUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img className="" src={whatsapp} alt="WhatsApp" />
                        </a>
                        <button
                          className="flex items-center gap-1 bg-primary px-8 py-2 rounded-[26px]"
                          onClick={() =>
                            handleCopyPhoneNumber(item.phone_number)
                          }
                        >
                          <img className="" src={callBTN} alt="callBTN" />
                          <p className="text-white">Call</p>
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {showRightButton && (
                <button
                  className="absolute right-0 top-0 bottom-[9%] flex items-center justify-center"
                  onClick={scrollRight}
                >
                  <img src={slideArrowRight} alt="" />
                </button>
              )}
              {showLeftButton && (
                <button
                  className="absolute left-0 top-0 bottom-[9%] flex items-center justify-center"
                  onClick={scrollLeft}
                >
                  <img src={slideArrowLeft} alt="" />
                </button>
              )}
            </div>
          </section>
        )}
      </main>
      <Modal
        title="Phone Number Copied"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <p>
          The phone number {phoneNumberToCopy} has been copied to your
          clipboard.
        </p>
      </Modal>
    </>
  );
};

export default ProductDetails;
